<template>
  <div>
    <div class="hero-main diamond-layout white-sec" :style="style">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-sm-12 col-md-6">
            <h1>The Road To <span>Financial Freedom!</span></h1>
            <p class="lead"><span class="emphasized">The Key to Financial Freedom and great wealth is a person's Ability or Skill to convert earned income into Passive Income and/or Portfolio Incom.</span> - "Robert Kiyosaki"</p>
            <div class="hero-btns">
              <a href="#distribution" class="btn el-btn">Explore</a>
            </div>
          </div>
          <div class="col-sm-12 col-md-6" data-wow-delay="0.5s">
            <div class="diamond-animation mobile-hidden">
              <div class="diamond-grid" />
              <div class="diamond-grid-2" />
              <div class="outer-Orbit">
                <div class="Orbit">
                  <div class="rotate">
                    <div class="OrbitSquare">
                      <div class="inner">A</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="main">
                <div class="top-coin"><span /></div>
                <div class="lines">
                  <span class="l-1" />
                  <span class="l-2" />
                  <span class="l-3" />
                  <span class="l-4" />
                  <span class="l-5" />
                  <span class="l-6" />
                  <span class="l-7" />
                  <span class="l-8" />
                  <span class="l-9" />
                  <span class="l-10" />
                  <span class="l-11" />
                  <span class="l-12" />
                  <span class="l-13" />
                  <span class="l-14" />
                  <span class="l-15" />
                </div>
                <div class="gris2" />
              </div>
              <div class="base" />
            </div>
            <div class="mobile-visible text-center">
              <img :src="diamondAnimationMobile" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- eslint-disable -->
    <div class="coingecko-widget">
      <div class="stipe-value-slider">
        <coingecko-coin-price-marquee-widget coin-ids="bitcoin,ethereum,binancecoin, elrond-erd-2, maiar-dex, cardano" currency="usd" background-color="transparent" locale="en"></coingecko-coin-price-marquee-widget>
      </div>
    </div>
    <div id="distribution" class="token-sale c-l">
      <div class="container mt-6">
        <div class="text-center"><h2 class="section-heading">Treasury <span>Allocation</span></h2></div>
        <div class="text-center"><h2 class="section-heading">Value: <span>~{{ getCoinsSum }}$</span></h2></div>
        <div v-if="getShare > 0" class="text-center"><h2 class="section-heading">Your share: <span>~{{ getShare }}$</span></h2></div>
        <div class="sub-txt text-center">
          <p>Our treasury allocation is presented below.</p>
        </div>
        <div class="row">
          <div class="col-md-12">
            <h3><span class="strong">Why become a holder?</span></h3>
            <p>Elros is built to be flexible, to maximize revenue generating opportunities for the project. At the core of this flexibility lies the Incubator.</p>
            <p>By holding Elros, you automatically become a beneficiary of the growing Treasury Vault. The Elros team's stance on the current bear market context is that it is both <span class="strong">temporary</span>, and a lucrative <span class="strong">opportunity to accumulate high-cap cryptocurrency assets.</span></p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <h3><span class="strong">Strategy</span></h3>
            <p>Considering these two basic premises, our goal is to use funds generated from events or services rendered to:
              <ul>
                <li><span class="strong">DCA</span> into <span class="strong">blue-chip cryptocurrencies</span> such as BTC, ETH, SOL or BNB. We believe such assets are low-risk   and guaranteed to generate profit once the next bull market starts.</li>
                <li>Buy   into <span class="strong">long-term  DeFi projects</span>, hand-picked with care.</li>
              </ul>
            </p>
            <p>Together, the Elros team has many <span class="strong">years of cumulative trading experience</span> in the crypto market. All team members are well-versed in risk management strategies. They will lead Elros’ strategy as to what assets to acquire and when is the best time to DCA.</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <h3><span class="strong">Community involvement</span></h3>
            <p>Elros holders will also be able to <span class="strong">vote</span> on the assets which will be added to the treasury in some cases. So far, this has been done in the form of anonymous polls, however these are not adequate for a larger project and a <span class="strong">fairer and more transparent system</span>, that considers the number of Elros tokens a voter owns, is <span class="strong">under development.</span></p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <h3><span class="strong">Transparency</span></h3>
            <p>Measures have been taken to ensure the <span class="strong">transparency</span> of the project, as well as the security of the <span class="strong">Treasury Vault</span>. The addresses of the treasury wallets are publicly visible, and all transactions will be shared on the project’s news channels.</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <h3><span class="strong">Wallet Addresses</span></h3>
            <div class="token-allocation-box row">
              <div class="wallet-container col-md-12">
                <h4>BSC/ETH/CRO</h4>
                <div class="wallet">
                  <a href="https://bscscan.com/address/0x54EFD1f059f520a83615C74486aa7d790f0521AB" target="_blank"><span>{{ bnbwa }}</span></a>
                  <a href="" @click.prevent="onCopy(bnbwa)"><i class="fa fa-copy" /></a>
                </div>
              </div>
              <div class="wallet-container col-md-12">
                <h4>Solana</h4>
                <div class="wallet">
                  <a href="https://explorer.solana.com/address/LgCixy9SEWBmub7fLR4aaUes4UMmDyUi39z25ZUuXeJ/tokens" target="_blank"><span>{{ solwa }}</span></a>
                  <a href="" @click.prevent="onCopy(solwa)"><i class="fa fa-copy" /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 wow zoomIn">
            <h4>Token Allocation</h4>
            <div class="sale-chart-view">
              <div class="doughnut">
                <div class="doughnutChartContainer">
                  <canvas id="layout2doughnutChart1" height="270" />
                </div>
                <div id="layout2legend1" class="chart-legend" />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <table>
              <tr>
                <th>Name</th>
                <th>Symbol</th>
                <th>Holdings</th>
              </tr>
              <tr v-for="(item, i) in treasury" :key="i">
                <td>
                  <a :href="item.link" target="_blank">
                    <img class="coin-logo" :src="item.img" :alt="`${item.name} logo`" />
                    <span class="item-name">{{ item.name }}</span>
                  </a>
                </td>
                <td>{{ item.symbol }}</td>
                <td>{{ item.holdings }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- Tier card section start -->
    <div class="token-sale" id="token">
                <div class="container">
                  <div class="sec-title text-center"><h3 class="section-heading">Tiers For <span>Elros Holders</span></h3></div>
                    <div class="sub-txt text-center">
                    </div>
                    <div class="row sale-boxes">
                        <div class="col-md-6 mb-4 sale-box wow fadeInUp" data-wow-iteration="1">
                            <div class="sale-box-inner">
                                <div class="sale-box-head">
                                    <h4>BRONZE HOLDERS</h4>
                                    <span>At Least 2.5M $ELRS</span>
                                </div>
                                <ul class="sale-box-desc">
                                    <li>
                                     <span>✓</span> 0.25% from distributed amount (in 2025)
                                    </li>
                                    <li>
                                     <span>✓</span> ELROS Army Access
                                    </li>
                                    <li><span class="note">*Please note to benefit of this perk you will need to hold at least 2.5M ELROS tokens. If you don't have that amount, you can always upgrade and buy more to benefit of this perk.</span></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6 mb-4 sale-box wow fadeInUp" data-wow-delay="0.1s">
                            <div class="sale-box-inner">
                                <div class="sale-box-head">
                                    <h4>SILVER HOLDERS</h4>
                                    <span>At Least 5M $ELRS</span>
                                </div>
                                <ul class="sale-box-desc">
                                    <li>
                                      <span>✓</span> 0.5% from distributed amount (in 2025)
                                    </li>
                                    <li>
                                      <span>✓</span> Incubator Program **
                                    </li>
                                    <li>
                                      <span>✓</span> FREE Entry for ELROS Events
                                    </li>
                                    <li>
                                      <span>✓</span> + Everything included in BRONZE HOLDERS perk
                                    </li>
                                    <li>
                                      <span class="note">
                                        *Please note to benefit of this perk you will need to hold at least 5M ELROS tokens. You can always upgrade to a higher perk (Gold or Diamond) by buying more ELROS tokens.
                                        <br>
                                        **Introducing you into the Incubator Program based on your skills (Graphic Design, Web Design, Group Moderation, Group Administration, etc.)
                                      </span></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6 mb-4 sale-box wow fadeInUp" data-wow-delay="0.2s">
                            <div class="sale-box-inner">
                                <div class="sale-box-head">
                                    <h4>GOLD HOLDERS</h4>
                                    <span>At Least 7.5M $ELRS</span>
                                </div>
                                <ul class="sale-box-desc">
                                    <li>
                                      <span>✓</span> 0.75% from the distributed amount (in 2025)
                                    </li>
                                    <li>
                                      <span>✓</span> Sniper Bot Setup
                                    </li>
                                    <li>
                                      <span>✓</span> Seed/Private (first come, first served)
                                    </li>
                                    <li>
                                      <span>✓</span> Whitelist spots (first come, first served)
                                    </li>
                                    <li>
                                      <span>✓</span> “24/7” Support/Guidance
                                    </li>
                                    <li>
                                      <span>✓</span> + Everything from SILVER HOLDERS perk
                                    </li>
                                    <li><span class="note">*Please note to benefit of this perk you will need to hold at least 7.5M ELROS tokens. You can always upgrade to a higher perk (Diamond) by buying more ELROS tokens.</span></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6 sale-box wow fadeInUp" data-wow-delay="0.2s">
                            <div class="sale-box-inner">
                                <div class="sale-box-head">
                                    <h4>DIAMOND HOLDERS</h4>
                                    <span>At Least 10M $ELRS</span>
                                </div>
                                <ul class="sale-box-desc">
                                    <li>
                                      <span>✓</span> 1% from distributed amount (in 2025)
                                    </li>
                                    <li>
                                      <span>✓</span> Private Group
                                    </li>
                                    <li>
                                      <span>✓</span> Sniper Bot Setup
                                    </li>
                                    <li>
                                      <span>✓</span> Seed/Private (guaranteed)
                                    </li>
                                    <li>
                                      <span>✓</span> Whitelist spots (guaranteed)
                                    </li>
                                    <li>
                                      <span>✓</span> Snipe Opportunities
                                    </li>
                                    <li>
                                      <span>✓</span> Copy-Trading
                                    </li>
                                    <li>
                                      <span>✓</span> Portfolio Management
                                    </li>
                                    <li>
                                      <span>✓</span> “24/7” Support/Guidance
                                    </li>
                                    <li><span class="note">*The Private Group is a very exclusive group where valuable information will be shared, for example: private calls and allocations, private investment opportunities, shared portfolios, investment ideas, meetings, and many many other valuable information.</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Tier card section end -->
  </div>
</template>

<script>
  import Vue from 'vue';
  import { mapActions, mapGetters } from 'vuex';
  import { nanoid } from 'nanoid';
  import { copyToClipboard } from '@/utils/copy-to-clipboard';
  import diamondAnimationMobile from '@/assets/png/diamond-animation-mobile.png';
  import backgroundBanner from '@/assets/jpg/banner-1.jpg';

  export default Vue.extend({
    name: 'elros-treasury',

    data: () => ({
      coins: [],
      diamondAnimationMobile,
      eglwa: 'erd1p45lc6f683ad57rjpferq0v2480avl4zre7wjz5klu9fp55rywqq5taywg',
      bnbwa: '0x54EFD1f059f520a83615C74486aa7d790f0521AB',
      solwa: 'LgCixy9SEWBmub7fLR4aaUes4UMmDyUi39z25ZUuXeJ',
      style: { backgroundImage: `url(${backgroundBanner})` },
      treasury: [
        {
          img: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
          link: 'https://coinmarketcap.com/currencies/ethereum/',
          name: 'Ethereum',
          symbol: 'ETH',
          holdings: '1.38',
        },
        // {
        //   img: 'https://s2.coinmarketcap.com/static/img/coins/64x64/825.png',
        //   link: 'https://coinmarketcap.com/currencies/tether/',
        //   name: 'Tether USDt',
        //   symbol: 'USDT',
        //   holdings: '250',
        // },
        {
          img: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5426.png',
          link: 'https://coinmarketcap.com/currencies/solana/',
          name: 'Solana',
          symbol: 'SOL',
          holdings: '30',
        },
        {
          img: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6892.png',
          link: 'https://coinmarketcap.com/currencies/multiversx-egld/',
          name: 'MultiversX',
          symbol: 'EGLD',
          holdings: '7',
        },
        {
          img: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png',
          link: 'https://coinmarketcap.com/currencies/bnb/',
          name: 'BNB',
          symbol: 'BNB',
          holdings: '4.97',
        },
        {
          img: 'https://s2.coinmarketcap.com/static/img/coins/64x64/25028.png',
          link: 'https://coinmarketcap.com/currencies/ordi/',
          name: 'ORDI',
          symbol: 'ORDI',
          holdings: '3',
        },
        {
          img: 'https://s2.coinmarketcap.com/static/img/coins/64x64/29393.png',
          link: 'https://coinmarketcap.com/currencies/azure/',
          name: 'Azure',
          symbol: 'AZR',
          holdings: '36,634',
        },
        {
          img: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8719.png',
          link: 'https://coinmarketcap.com/currencies/illuvium/',
          name: 'Illuvium',
          symbol: 'ILV',
          holdings: '1',
        },
        {
          img: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2424.png',
          link: 'https://coinmarketcap.com/currencies/singularitynet/',
          name: 'SingularityNET',
          symbol: 'AGIX',
          holdings: '250',
        },
        {
          img: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14899.png',
          link: 'https://coinmarketcap.com/currencies/xexchange/',
          name: 'xExchange',
          symbol: 'MEX',
          holdings: '5,700,000',
        },
        {
          img: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3635.png',
          link: 'https://coinmarketcap.com/currencies/cronos/',
          name: 'Cronos',
          symbol: 'CRO',
          holdings: '743',
        },
        {
          img: 'https://bscscan.com/token/images/fairtokencake_32.png',
          link: 'https://www.coingecko.com/en/nft/ftc-official',
          name: 'FairToken Cake',
          symbol: 'FRTC',
          holdings: '2,000,000',
        },
        {
          img: 'https://s2.coinmarketcap.com/static/img/coins/64x64/24698.png',
          link: 'https://coinmarketcap.com/currencies/hypercycle/',
          name: 'HyperCycle',
          symbol: 'HYPC',
          holdings: '1,000',
        },
        {
          img: 'https://etherscan.io/token/images/blockstarsite_32.png',
          link: 'https://blockstar.site/',
          name: 'BlockStar',
          symbol: 'BST',
          holdings: '5,000',
        },
        {
          img: 'https://s2.coinmarketcap.com/static/img/coins/64x64/27486.png',
          link: 'https://coinmarketcap.com/currencies/sophiaverse/',
          name: 'SophiaVerse',
          symbol: 'SOPH',
          holdings: '2,770',
        },
        {
          img: 'https://bscscan.com/token/images/nnncommunity_32.png?v=2',
          link: 'https://coinbrain.com/coins/bnb-0xcb008773ebef8c527fc33a4382659b13c9e73f70',
          name: 'No Purpose Token',
          symbol: 'NNN',
          holdings: '126,000,000,000',
        },
      ],
    }),
    computed: {
      ...mapGetters({
        getCoinsList: 'common/getCoinsList',
        getCoins: 'common/getCoins',
        getHoldings: 'session/getHoldings',
      }),
      getCoinsSum() {
        let sum = 0;

        this.getCoinsList.forEach((coin) => {
          const selectedCoin = this.getCoins.find(item => item.symbol === coin.symbol);
          const total = selectedCoin ? selectedCoin.value * coin.usd_price : 0;

          sum += total;
        });
        return (sum).toFixed(2);
      },
      getShare() {
        const share = this.getHoldings;
        let value = 0;

        if (share) {
          value = (this.getCoinsSum * (this.getHoldings / 10000000)) / 65;
        }

        return value.toFixed(2);
      },
    },
    mounted() {
      window.initTreasuryAnimation();
      window.initTreasury();
    },
    created() {
      this.fetchCoinsList();
    },
    methods: {
      ...mapActions({
        fetchCoinsList: 'common/fetchCoinsList',
      }),
      onCopy(txt) {
        copyToClipboard(txt);
        this.$toasts.toast({
          id: nanoid(),
          message: 'The link has been copied to clipboard',
          title: 'Success',
          intent: 'success',
        });
      },
    },
  });
</script>
<style>
.wallet-container {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
  padding: 0 10px;
}

.wallet-container h4 {
  font-size: 18px;
  text-transform: uppercase;
}

.wallet-container .wallet {
  font-size: 15px;
  line-height: 1.8;
  text-transform: uppercase;
}

.wallet-container .wallet span {
  color: #eec05e;
  display: block;
  font-weight: bold;
}

@media only screen and (max-width: 1199px) {
  .wallet-container .wallet {
    font-size: 12px;
  }

  .wallet-container .egld-wallet {
    font-size: 8px;
  }
}

table {
  border-collapse: collapse;
  font-family: arial, sans-serif;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  padding: 8px;
  text-align: left;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

td img {
  border-radius: 12px;
  height: 24px;
  margin-right: 8px;
  width: 24px;
}

.item-name {
  color: #cd8812;
  font-weight: bold;
}

</style>
